.subHeadings {
    font-size: 1.125rem;
    margin-bottom: 1.688rem;
    text-align: center;
}

.cardContent {
    font-size: 1.25rem;
    /* color: #FFF; */
}

.cardContainer {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    overflow-x: scroll;
}

.readMore {
    /* width: 4.513vw; */
    /* border-bottom: 1px solid #333333; */
}

.readMeLine {
    width: 0;
    height: 1px;
    border: none !important;
    margin: 0;
    /* background-color: #44CCFF; */
    margin-top: 2px;
}

.readMore:hover .readMeLine {
    width: 100%;
    transition: width 0.5s;
}

.tabStyle {
    /* color: #ccc !important; */
    text-transform: capitalize !important;
    font-size: 0.75rem !important;
    width: 8.0625rem !important;
}