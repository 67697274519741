.mainHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 0.625rem;
    align-items: center;
}

.menuButtonContainer{
    display: flex;
    flex-direction: column;
    width: 1.563rem;
    height: 1.563rem;
    gap: 1px;
    /* justify-content: space-evenly; */
    padding: 0 !important;
}

/* .menuButtonContainer button{
    height: 100%;
    width: 100%;
    background-color: transparent;
} */

.leftContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 2px;
}

.leftContainer .img{
    height: 1.093rem;
    width: auto;
}

.rightContainer{
    display: flex;
    /* flex-direction: row; */
    gap: 1rem;
    align-items: center;
    justify-content: space-evenly;
    /* width: 20%; */
    font-size: 0.75rem;
    gap: 0.6rem
}

.rightContainer a{
    /* color: #44ccff; */
}

.MenuContainer{
    position: absolute;
    top: 0;
    display: none;
    left: -50px;
    background-color: #000000;
    z-index: 100;
    width: 16.25rem;
    height: 100%;
    transition: left 2s ease;
    z-index: 11;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.isOpen{
    left: 0;
    display: block;
}

.menuLogoContaier{
    padding: 1.438rem 1rem 1.188rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-right: 1px solid #333333; */
}

.menuLogoContaier .img1{
    width: 5rem;
    height: 0.875rem;
}

.MenuContainer ul{
    list-style: none;
    padding: 0;
    margin-top: 0;
}

.MenuContainer li{
    padding: 0.75rem 0 0.75rem 2rem;
    /* border-right: 1px solid #333333; */
    /* border-bottom: 1px solid #333333; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

/* .MenuContainer li:last-child{
    border-bottom-right-radius: 20px;
} */

/* .MenuContainer ul li {
    border-bottom: 1px solid #44ccff;
} */

.MenuContainer ul li a{
    text-decoration: none;
    color: #ccc;
    font-size: 0.75rem;
}