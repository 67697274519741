/* variables */
::-ms-reveal {
  display: none;
}
:root {
  /* Dark mode */
  /* background-colors */
  --primary-bg-color: #000000;
  --secondary-bg-color: #2e2d2d40;
  --tertiary-bg-color: #333333;
  --bg-color-4: #4e4e4e;
  --bg-color-5: #8f8f8f;
  --bg-color-6: #2e2d2d80;
  --bg-color-7: #262525;
  --bg-color-8: #cccccc;
  --bg-color-9: #ffffff;
  /* level-colors */
  --primary-color-lvl-1: #44ccff;
  --secondary-color-lvl-1: #87dcfc;
  --primary-color-lvl-2: #7fff00;
  --secondary-color-lvl-2: #99fc3a;
  --primary-color-lvl-3: #ffff00;
  --secondary-color-lvl-3: #ebfb4c;
  --primary-color-lvl-4: #cc0000;
  --secondary-color-lvl-4: #e50404;
  --primary-color-lvl-5: #ff9900;
  --secondary-color-lvl-5: #fca92c;
  --nonactivetab-color: #cccccc80;
  --nonactivetab-lgt-color: #33333380;

  /* TextField input bg color */
  --input-bg-color: #2e2d2d1a;

  /* border-colors */
  --border:1px solid #333333;
  /* other colors */
  /* small podium */
  --sm-podium-color-1: #020201;
  --sm-podium-color-2: #1e1e1e;

  /* big podium */
  --lg-podium-color-1: #060506;
  --lg-podium-color-2: #1f1f1f;

  /* Achievement Gradient */
  --ach-color-1: #000000;
  --ach-color-2: #cccccc;

  /* landing page svg colors */
  --smartsa-char-background:#333333;
  --smartsa-char-color:#ffffff;
  --smartsa-card-border:#333333;
  --smartsa-card-text:#ffffff;
  --smartsa-card-background:#2e2d2d40;
  --smartsa-card-icon-color:#ffffff;

  /* Light Mode */
  /* background-colors */
  --lgt-primary-bg-color: #ffffff;
  --lgt-secondary-bg-color: #eef0f3;
  --lgt-tertiary-bg-color: #dee2e6;
  --lgt-bg-color-4: #bcbcbc;
  --lgt-bg-color-5: #434547cc;
  --lgt-bg-color-6: #444648;
  --lgt-bg-color-7: #000000;

  /* level-colors */
  --lgt-primary-color-lvl-1: #54b5f0;
  --lgt-secondary-color-lvl-1: #9fd9fc;
  --lgt-primary-color-lvl-2: #b3e56a;
  --lgt-secondary-color-lvl-2: #bfee79;
  --lgt-primary-color-lvl-3: #ffe565;
  --lgt-secondary-color-lvl-3: #ebfb4c;
  --lgt-primary-color-lvl-4: #ff7860;
  --lgt-secondary-color-lvl-4: #ff8e7a;
  --lgt-primary-color-lvl-5: #fbb135;
  --lgt-secondary-color-lvl-5: #fcbb4d;

  /* border */
  --lgt-border:1px solid #dee2e6;
  /* other colors */
  /* small podium */
  --lgt-sm-podium-color-2: #dee2e6;
  --lgt-sm-podium-color-1: #C2C5CB;

  /* big podium */
  --lgt-lg-podium-color-2: #dee2e6;
  --lgt-lg-podium-color-1: #C2C5CB;

  /* Achievement Gradient */
  --lgt-ach-color-1: #000000;
  --lgt-ach-color-2: #dee2e6;

  /* Lab Cards */
  --lab-card-bg-color: #2e2d2d25;

  /* Terms & Privary Color */
  --terms-privacy-color: #2e2d2d;

  /* landing page svg colors */
  --lgt-smartsa-char-background:#444648;
  --lgt-smartsa-char-color:#ffffff;
  --lgt-smartsa-card-border:#DEE2E6;
  --lgt-smartsa-card-text:#444648;
  --lgt-smartsa-card-background:#c2c6cb40;
  --lgt-smartsa-card-icon-color:#444648;

}

html,
*,
body {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Mulish", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #000000;  */
  /* color: #cccccc; */
  /* overflow-x: hidden; */
}


html,body,*{
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; /* For removing Blink effect Our Story(mobile) */
}

/* *{
  -ms-overflow-style: none;
} */
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @media screen and (max-width: 37.5rem) {
  body {
    width: 25.75rem;
  }
} */

.heading {
  font-weight: normal;
  margin: 0%;
  padding: 0%;
  font-size: 1.125rem;
}

.headingDesktop {
  font-weight: normal;
  margin: 0%;
  padding: 0%;
  font-size: 2.564vw;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pageContainer {
  margin-top: 3.25rem;
}

.pageContainerDesktop {
  margin: 5.5vw 2vw 1vw 4.5vw;
}

.questionPage {
  font-size: 1rem;
  /* color: #cccccc; */
}

.questionPageDesktop {
  font-size: 1.154vw;
  /* color: #cccccc; */
}

.instructionsContainer {
  margin: 1rem 0rem;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem;
  border-radius: 10px;
  /* border: 3px solid #333333; */
}

.instructionsContainerDesktop {
  margin: 1.026vw 0rem;
  font-weight: 500;
  font-size: 1.026vw;
  line-height: 1.25;
  padding: 0.769vw;
  border-radius: 10px;
  /* border: 3px solid #333333; */
  width: 32.564vw;
}

.instructionsHeader {
  margin-bottom: 0.375rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.instructionsHeaderDesktop {
  margin-bottom: 1vw;
  font-size: 1.538vw;
  font-weight: 600;
  line-height: 1.5rem;
}

.authButtons {
  padding: 0% !important;
  height: 2.8125rem !important;
  font-size: 0.875rem !important;
  text-transform: capitalize !important;
  /* background-color: #44ccff !important; */
  width: 28.125vw;
}

.authMobile {
  padding: 0% !important;
  height: 2.8125rem !important;
  font-size: 0.875rem !important;
  text-transform: capitalize !important;
}

#pdf-container {
  pointer-events: none !important;
  user-select: none;
}

/* Add padding to Swiper container to accommodate scaling */
.mySwiper {
  padding: 0 0.962vw; /* Adjust based on how much extra space you need */
  overflow: hidden; /* Hide overflow to prevent clipped content */
}

.mySwiper .swiper-wrapper {
  padding: 2.885vw 0.962vw; /* Add padding inside the wrapper if needed */
}

.mySwiper .swiper-slide {
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.8; /* Dim non-center slides for better effect */
}

.mySwiper .swiper-slide-active {
  transform: scale(1.1); /* Scale up the center slide */
  opacity: 1; /* Fully opaque for the center slide */
}
html {
  scroll-behavior: auto;
}
/* Mobile-specific styles */
@media (max-width: 767px) {
  .mySwiper {
    padding: 0 3.641vw; /* Adjust based on how much extra space you need on mobile */
  }

  .mySwiper .swiper-wrapper {
    padding: 10.922vw 3.641vw; /* Add padding inside the wrapper for mobile */
  }

  .mySwiper .swiper-slide {
    opacity: 0.7; /* Dim non-center slides more on mobile */
  }

  .mySwiper .swiper-slide-active {
    transform: scale(1.05); /* Scale up the center slide less on mobile */
    opacity: 1; /* Fully opaque for the center slide */
  }
}

.video-responsive {
  overflow: hidden;
  position: relative;
  width: 54.744vw;
  height: 30.793vw;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.MuiGrid-root > .MuiGrid-item {
  padding: 16px 0 0 16px !important;
}

.signupFields {
  width: 32.051vw !important;
}

/* Dark Mode */
.dark-mode {
  background: #000000;
  color: #cccccc;
}

/* Global CSS */

/* fonts */

.heading-0 {
  font-size: 2.564vw;
  font-weight: 600;
}

.heading-1-bold {
  font-size: 1.538vw;
  font-weight: 600;
}

.heading-1-light {
  font-size: 1.538vw;
  font-weight: 400;
}

.heading-2-bold {
  font-size: 1.282vw;
  font-weight: 800;
}

.heading-2-medium {
  font-size: 1.282vw;
  font-weight: 600;
}

.heading-2-light {
  font-size: 1.282vw;
  font-weight: 400;
}

.heading-3-bold {
  font-size: 1.154vw;
  font-weight: 600;
}

.heading-3-light {
  font-size: 1.154vw !important;
  font-weight: 400;
}

.heading-4-bold {
  font-size: 1.026vw !important;
  font-weight: 500;
}

.heading-4-light {
  font-size: 1.026vw;
  font-weight: 400;
}

.heading-5 {
  font-size: 0.897vw;
  font-weight: 400;
}

.heading-6 {
  font-size: 0.833vw;
  font-weight: 400;
}

.heading-7 {
  font-size: 0.769vw;
  font-weight: 400;
}

/* Wrapper */
.navbar-dark{
  background-color: var(--primary-bg-color);
  border-top: 1px solid #333333;
  border-bottom: 0.5px solid var(--tertiary-bg-color);
}

.sidebar-dark{
  border-right: 1px solid #333333;
  background-color: var(--primary-bg-color);
}

.navbar-light{
  background-color: var(--lgt-secondary-bg-color);
  border-bottom: 0.5px solid var(--lgt-tertiary-bg-color);
}

.tab-dark-mode button span{
  border: 1px solid var(--bg-color-8) !important;
}

.tab-light-mode button span{
  border: 1px solid var(--lgt-bg-color-6) !important;
}

/* Universal */
.light{
 background-color: var(--lgt-secondary-bg-color);
 
 color:var(--lgt-bg-color-6)
}

.light-text{
  color:var(--lgt-bg-color-6)
}

.light-border{
  border: 1px solid var(--lgt-tertiary-bg-color) !important;
}

.dark-border{
  outline: 1px solid var(--tertiary-bg-color) !important;
}

.dark-border-btm{
  border-bottom: 1px solid var(--tertiary-bg-color);
}

.dark-hr{
  background-color:  var(--tertiary-bg-color);
}

.light-border-btm{
  border-bottom: 1px solid var(--lgt-tertiary-bg-color);
}

.light-hr{
  background-color:  var(--lgt-tertiary-bg-color);
}

.dark{
  background-color: var(--primary-bg-color);
  color:var(--bg-color-8)
}

.dark2{
  background-color: var(--secondary-bg-color);
}



.dark-text{
  color:var(--bg-color-8)
}

.card-dimensions{
    width: 20.512vw;
    height: 11.54vw;
    
}

.card-darker{
  border: 1px solid var(--tertiary-bg-color) !important;
  background-color: var(--primary-bg-color) !important;
  border-radius: 6px;
  color:var(--bg-color-8) !important;
}

.right-sidenav-dimensions{
  width: 25.64vw;
  /* background-color: transparent; */
}

.card-dark{
  border: 1px solid var(--tertiary-bg-color) !important;
    background-color: var(--lgt-bg-color-7) !important;
    border-radius: 6px;
    color:var(--bg-color-8) !important;
}

.card-dark2{
  border: 1px solid var(--tertiary-bg-color) ;
    background-color: var(--secondary-bg-color) ;
    border-radius: 6px;
    color:var(--bg-color-8) ;
}

.card-dark3{
  border: 1px solid var(--tertiary-bg-color) ;
    background-color: var(--primary-bg-color) !important;
    border-radius: 6px;
    color:var(--bg-color-8) ;
}

.card-light{
  background-color: var(--lgt-secondary-bg-color) !important;
  border: 1px solid var(--lgt-tertiary-bg-color) !important;
  border-radius: 6px;
  color:var(--lgt-bg-color-6) !important
}
.card-light-timelinefilter-mb{
  background-color: var(--lgt-secondary-bg-color);
  border: 1px solid var(--lgt-tertiary-bg-color) !important;
  border-radius: 6px;
  color:var(--lgt-bg-color-6) !important
}
.card-dark-timelinefilter-mb{
  border: 1px solid var(--tertiary-bg-color) !important;
    background-color: var(--secondary-bg-color);
    border-radius: 6px;
    color:var(--bg-color-8) !important;
}

.card-light2{
  background-color: var(--lgt-tertiary-bg-color);
  border: 1px solid var(--lgt-tertiary-bg-color);
  border-radius: 6px;
  color:var(--lgt-bg-color-6);
}

.light2{
  background-color: var(--lgt-tertiary-bg-color);
}


.rank-dark{
  background-color: inherit !important;
}

.rank-light{
  background-color: var(--lgt-tertiary-bg-color) !important;
}

/* Dashboard */
.dashboard-card {
  width: 20.512vw;
  height: 11.54vw;
  outline: 1.6px solid var(--tertiary-bg-color);
  background-color: var(--secondary-bg-color);
}

.dashboard-card-light{
  background-color: var(--lgt-secondary-bg-color) !important;
  outline: 1.6px solid var(--lgt-tertiary-bg-color);

}

.coin-button-light{
  background-color: var(--lgt-secondary-bg-color) !important;
  color: var(--lgt-bg-color-6) !important;
}

.coin-button-light span{
  color: var(--lgt-bg-color-6) !important;
}

/* LabPage */
.lab-card-dark {
  width: 24.103vw !important;
  background-color: var(--primary-bg-color) !important;
  border: 2PX SOLID var(--tertiary-bg-color);
  color: var(--bg-color-9) !important;
  cursor: pointer;
}
.lab-card-light {
  width: 24.103vw !important;
  color: var(--primary-bg-color) !important;
  background-color: var(--lgt-secondary-bg-color) !important;
  border: 2.5px solid var(--lgt-tertiary-bg-color) !important;
  cursor: pointer;
}
.lab-readMore-dark {
  /* width: 4.513vw; */
  border-bottom: 1px solid var(--tertiary-bg-color);
}
.lab-sidenav-light{
  border-color: var(--lgt-tertiary-bg-color) !important;
  background-color: var(--lgt-secondary-bg-color) !important;
}
.lab-sidenav-light a{
  color: var(--lgt-bg-color-6);
  border-color: var(--lgt-tertiary-bg-color) !important;
}
.lab-readMore-light {
  /* width: 4.513vw; */
  border-bottom: 1px solid var(--ach-color-2);
}
.lab-card-mobile-dark{
  width: 90.291vw !important;
  background-color: var(--primary-bg-color) !important;
  border: 2PX SOLID var(--tertiary-bg-color);
  color: var(--bg-color-9) !important;
  cursor: pointer;
}
.lab-card-mobile-light{
  width: 90.291vw !important;
  background-color: var(--lgt-secondary-bg-color) !important;
  outline: 2px solid var(--lgt-tertiary-bg-color) !important;
  cursor: pointer;
}
/* Lab Details Page */

.learn-sidenav-light{
  border-color: var(--lgt-tertiary-bg-color) !important;
  background-color: var(--lgt-secondary-bg-color) !important;
}
.learn-sidenav-light a{
  color: var(--lgt-bg-color-6);
  border-bottom: 2px solid var(--lgt-tertiary-bg-color);
}
.learn-sidenav-dark a{
  border-bottom: 2px solid #2E2D2D !important;
}
.signup-band-light{
  background-color: var(--lgt-secondary-bg-color) !important;
  border: 1px solid var(--lgt-tertiary-bg-color) !important;
  /* border-radius: 6px; */
  color:var(--lgt-bg-color-6) !important
}
/* Blogs Page */
.blog-sidenav-light{
  background-color: var(--bg-color-9) !important;
}
.blog-sidenav-content-dark{
  border-right: 2px solid var(--tertiary-bg-color) !important;
}
.blog-sidenav-content-light{
  border: 1.5px solid var(--lgt-tertiary-bg-color);
  background-color: var(--lgt-secondary-bg-color) !important;
}
.blog-sidenav-content-light a{
  color: var(--lgt-bg-color-6) !important;
}
.blog-card-light{
  background-color: var(--lgt-secondary-bg-color) !important;
  border: 2px solid var(--lgt-tertiary-bg-color) !important;
}

.sidenav-light {
  border-color: var(--lgt-tertiary-bg-color) !important;
  background-color: var(--lgt-secondary-bg-color) !important;
}

.sidenav-light a {
  color: var(--lgt-bg-color-6);
  border-bottom: 2px solid var(--lgt-tertiary-bg-color);
}

.sidenav-dark a{
  /* border-bottom: 2px solid #2E2D2D !important; */
}

/* Footer */
.footerContainer-light a{
  color: var(--lgt-bg-color-6);
}

/* Mobile Menu Container */
.MenuContainer-light-mobile {
  background-color: var(--lgt-secondary-bg-color) !important;
  color: var(--primary-bg-color) !important;
  /* border-right: 1px solid #333333;
  border-bottom: 1px solid #333333; */
  /* var(--lgt-tertiary-bg-color) */
}
.MenuContainer-light-mobile a {
  color: var(--lgt-bg-color-6) !important;
}

/* Timeline Graph page */
.card-dark-timeline{
  border: 1px solid var(--tertiary-bg-color);
  background-color: var(--secondary-bg-color) !important;
  color:var(--bg-color-8) !important;
}
.card-light-timeline{
  background-color: var(--lgt-secondary-bg-color) !important;
  border: 1px solid var(--lgt-tertiary-bg-color);
  color:var(--lgt-bg-color-6) !important;
}

.score-button-light {
  background-color: var(--lgt-secondary-bg-color) !important;
  border: 1px solid var(--lgt-tertiary-bg-color);
}

.score-button-dark {
  background-color: var(--primary-bg-color);
  border: 1px solid var(--tertiary-bg-color);
}


.card-light-cointracker{
  background-color: #c2c6cb26 !important;
  border: 1px solid var(--lgt-tertiary-bg-color) !important;
  border-radius: 6px;
  color:var(--lgt-bg-color-6) !important
}

.mission-q-card-dark{
  background: var(--secondary-bg-color);
}
.mission-q-card-light{
  background: var(--lgt-smartsa-card-background);
  border: 1px solid var(--lgt-tertiary-bg-color);
}

/* LeaderBoard */
.card-light-leaderboard {
  background-color: #bcbcbc3b !important;
  border: 1px solid var(--lgt-tertiary-bg-color) !important;
  border-radius: 6px;
  color:var(--lgt-bg-color-6) !important
}

/* Terms & Privacy */
.terms-sidenav-light{
  border-color: var(--lgt-tertiary-bg-color) !important;
  background-color: var(--lgt-secondary-bg-color) !important;
}
.terms-sidenav-light a{
  color: var(--lgt-bg-color-6);
  border-color: var(--lgt-tertiary-bg-color) !important;
}

/* For Dialog Boxes */
.dialog-box {
  background: var(--primary-bg-color) !important;
  border: 1px solid #333;
}

.dialog-box-light {
  background: var(--bg-color-9) !important;
}

/* For Mobile View */
@media (max-width: 767px) {
  /* fonts */
  .heading-0 {
    font-size: 2.5rem;
    font-weight: 600;
  }
  .heading-1-bold {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .heading-1-light {
    font-size: 1.5rem;
    font-weight: 400;
  }
  .heading-2-bold {
    font-size: 1.25rem;
    font-weight: 800;
  }
  .heading-2-medium {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .heading-2-light {
    font-size: 1.25rem;
    font-weight: 400;
  }
  .heading-3-bold {
    font-size: 1.13rem;
    font-weight: 600;
  }
  .heading-3-light {
    font-size: 1.13rem;
    font-weight: 400;
  }
  .heading-4-bold {
    font-size: 1rem;
    font-weight: 500;
  }
  .heading-4-light {
    font-size: 1rem;
    font-weight: 400;
  }
  .heading-5 {
    font-size: 0.875rem;
    font-weight: 400;
  }
  .heading-6 {
    font-size: 0.8125rem;
    font-weight: 400;
  }
  .heading-7 {
    font-size: 0.75rem;
    font-weight: 400;
  }

  .card-dimensions{
    width: 7.6875rem;
    height: 5rem;
  }
}
