.pageTitle {
    font-size: 1rem;
}

.paraStyle {
    font-size: 0.75rem;
    line-height: 0.941rem;
    text-align: left;
    
}

.subTitle {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.125rem;
    text-align: left;
    /* color: #44ccff; */
}

.paraDiv {
    margin: 1.5rem;
}

.activeList {
    background-color: #000;
}
.consultButton{
  padding: 0.326rem 0.735rem;
  border-radius: 3px;
  border: none;
  font-size: 10px;
  width: fit-content;
  /* background-color: #44CCFF; */
  cursor: pointer;
}