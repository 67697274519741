.textContainer{
    /* height: 100%; */
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
}

.headingContainer{
    width: 100%;
}

.headingContainer h1{
    font-weight: bold;
    /* color: #7FFF00; */
    text-align: center;
    font-size: 3.125rem;
    margin-bottom: 2vw;
}


.subheadingContainer{
    margin: 0;
    margin-top:0.5rem;
    font-size: 0.875rem;
}

.dynamicContentContainer{
    text-align: center;
}

dialog:modal {
    max-width: 100vw;
  }

dialog{
    background-color: #000000;
    width: 100vw !important;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
}


dialog::backdrop{
    display: none;
}

.contentList{
    /* color: #7FFF00; */
}

.contentListElement{
    margin-top: 1rem;
    font-size:0.75rem;
}
 
.subContainer{
    /* color: #ffffff; */
    font-size: 0.875rem
}

.closeButton{
    position: absolute;
    top: 1rem;
    right: 1rem;
}